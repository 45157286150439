@import "color_palette";

$content-width: 998px;

/* Layout */

body {
  background-color: $beige;
  font-family: Georgia, Times, Times New Roman, serif;

  #container {
    width: $content-width; // Fixed width
    margin: 0 auto; // Center the container
    background-color: white;
    margin-top: 20px; // Vertical padding
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); // Subtle shadow around the container

    header {
      background-color: white;
      text-align: center;
      height: 288px;
      margin-bottom: 3px;

      #header-banner {
        border-style: solid;
        border-width: 1px 0 0 0;
        border-color: $beige-dark;
        margin: 0;
      }
    }

    nav#site-nav {
      background-color: black;
      padding: 10px 0px 10px 15px;
      text-align: left;

      a, .current {
        color: white;
        text-decoration: none;
        margin: 0 15px;
        display: inline-block; // Ensure horizontal alignment

        // Styling for the current page indicator
        &.current {
          cursor: default;
          font-weight: bold;
        }
      }

      a:hover {
        color: white;
        border-bottom: 0;
      }
    }

    .content-frame {
      padding: 30px 30px 20px 30px;
      background-color: white;
      
      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }

      .content {
        padding: 0 89px;
      }

      .pagination {

        .post-excerpt {
          padding: 0;

          .post-title {
            a {
              color: $color-text;

              &:hover {
                color: $color-fade;
                border-bottom: 0;
              }
            }
          }

          .post-meta {
            margin: 0 0 20px 0;
          }

          .post-see-more {
            padding: 0 0 5px 0;
            font-size: smaller;
            text-align: right;
          }
        }

        hr.sep {
          border-top: 2px dotted $mid-grey;
          border-right: none;
          border-bottom: none;
          border-left: none;
          background-color: transparent;
          height: 0;
          margin: 1.5rem 0 5rem 0;
        }

        .pagination-trail {
          display: flex;
          justify-content: center;
          list-style: none;
          margin: 20px 0 0 0;
          padding: 0;

          .page-link {
            display: inline-block;
            padding: 6px 12px;
            margin: 0 4px;
            border: 1px solid #ddd;
            border-radius: 2px;
            color: #337ab7;
            text-decoration: none;

            &:hover {
              background-color: #eee;
              border-color: #ddd;
            }

            &.active {
              background-color: #337ab7;
              color: white;
              border-color: #337ab7;
            }
          }
        }
      }

      .post-header {
        .post-navigation {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 0 15px 0;
          border-bottom: 1px dotted $mid-grey;
          margin-bottom: 30px;

          .post-metadata {
            span {
              margin-right: 10px;
              font-size: 14px;
              color: #555;
            }
          }

          .post-nav {
            .post-nav-button {
              text-decoration: none;
              color: #337ab7;
              margin-left: 10px;
              padding: 3px 10px 3px 10px;
              border: 1px solid #337ab7;
              border-radius: 4px;
              // font-size: 14px;

              &:hover {
                background-color: #337ab7;
                color: white;
              }

              &.disabled {
                color: #aaa;
                border-color: #aaa;
                background-color: #f5f5f5;
                cursor: not-allowed;
                pointer-events: none; // Ensure the button is not clickable

                &:hover {
                  background-color: #f5f5f5; // No change on hover for disabled button
                  color: #aaa;
                }
              }
            }
          }
        } // end .post-header
      }

      .post {}

      .post-footer {
        .post-tags {
          padding-top: 25px;

          span {
            margin-right: 10px;
            font-size: 14px;
            color: #555;
          }
        }
      }
    }

    hr {
      border: none; // Remove default border
      height: 1px; // Line thickness
      background-color: $mid-grey; // Line color
      margin: 0;
    }

    footer {
      background-color: $subtle-grey;
      padding: 20px;
      text-align: center;
      color: black;
      border-top: 1px;
    }
  }
}